/**
 * components/_buttons.scss Buttons
 *
 * Write a description
 */
 
 .btn{
	cursor: pointer;
	display: inline-block;
}
