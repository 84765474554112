/**
 * components/_background.scss Background
 *
 * Write a description
 */

 /**
 * Background image
 *
 * Write a description
 */

.bg-image,
.bg-img
{
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
}