/**
 * utilities/_reset.scss Reset
 *
 * Write a description
 */

html {
  box-sizing: border-box;
  position:relative; /*prevent bug 32px wordpress admin bar*/
}
*, *:before, *:after {
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	font-style: Normal;
  font-weight: Normal;
}

html,
body,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p
{
	padding:0px;
	margin:0px;
}

li{list-style:none;}
a{text-decoration:none;}

a,
a:hover,
a:focus,
a:active,
a:visited
{
	color:currentcolor;
}

b{font-weight:bold;}
i{font-style: italic;}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
